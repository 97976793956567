@charset "UTF-8";
/*!
 * Web Fonts from Fontspring.com
 *
 * All OpenType features and all extended glyphs have been removed.
 * Fully installable fonts can be purchased at http://www.fontspring.com
 *
 * The fonts included in this stylesheet are subject to the End User License you purchased
 * from Fontspring. The fonts are protected under domestic and international trademark and
 * copyright law. You are prohibited from modifying, reverse engineering, duplicating, or
 * distributing this font software.
 *
 * (c) 2010-2020 Fontspring
 *
 *
 *
 *
 * The fonts included are copyrighted by the vendor listed below.
 *
 * Vendor:      Mårten Nettelbladt
 * License URL: https://www.fontspring.com/licenses/marten-nettelbladt/webfont
 *
 *
 */
@font-face {
  font-family: "MisoRegular";
  src: url("miso-regular.woff2") format("woff2"), url("miso-regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
.button, nav.admin a, input[type=submit], textarea[type=submit], .small_button {
  text-decoration: none;
  border: none;
  display: inline-block;
  padding: 0.2em 0.5em;
  color: #fff;
  background: #a28522;
  font-size: 2.4rem;
  cursor: pointer;
}

.small_button {
  font-size: 1.8rem;
}

.add_button, .delete_button, a.remove_nested_fields, a.add_nested_fields, div.shopping_cart_form a.add_variant {
  display: inline-block;
  background: #31333C;
  line-height: 1.4em;
  text-decoration: none;
  color: #fff;
  background-image: url("plus.png");
  background-repeat: no-repeat;
  background-position: 8px center;
  padding: 0 15px 0 30px;
  border-radius: 12px;
}

div.flash {
  margin: 0;
  position: fixed;
  top: 0;
  z-index: 4;
  padding: 102px 30px 20px 80px;
  width: 100%;
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-position: 20px 92px;
  background-size: 40px 40px;
  opacity: 1;
  transition: opacity 0.5s ease-out;
}
div.flash a.close, div.flash a.close:link, div.flash a.close:visited, div.flash a.close:hover, div.flash a.close:active {
  text-decoration: none;
  position: absolute;
  bottom: 20px;
  right: 20px;
}
div.flash.fade-out {
  opacity: 0;
}

div#notice {
  background-color: #f6f9ec;
  color: #83a500;
  border-bottom: 2px solid #99cc00;
  background-image: url("ok.png");
}

div#alert {
  background-color: #fdece4;
  color: #FF0000;
  border-bottom: 2px solid #FF0000;
  background-image: url("not_ok.png");
}

.button, nav.admin a, input[type=submit], textarea[type=submit], .small_button {
  text-decoration: none;
  border: none;
  display: inline-block;
  padding: 0.2em 0.5em;
  color: #fff;
  background: #a28522;
  font-size: 2.4rem;
  cursor: pointer;
}

.small_button {
  font-size: 1.8rem;
}

.add_button, .delete_button, a.remove_nested_fields, a.add_nested_fields, div.shopping_cart_form a.add_variant {
  display: inline-block;
  background: #31333C;
  line-height: 1.4em;
  text-decoration: none;
  color: #fff;
  background-image: url("plus.png");
  background-repeat: no-repeat;
  background-position: 8px center;
  padding: 0 15px 0 30px;
  border-radius: 12px;
}

html {
  height: 100%;
  font-size: 10px;
  background: #ffffff;
}

body {
  margin: 0;
  padding: 0;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  font-family: "MisoRegular", Arial, Helvetica, sans-serif;
  color: #333333;
  font-size: 2.3em;
  overflow-y: scroll;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+11 CSS styles go here */
  body {
    display: block;
  }
}
body > header {
  display: block;
  position: relative;
  flex: none;
  z-index: 5;
}
body > header a.logo {
  box-sizing: border-box;
  height: 76px;
  display: block;
  position: relative;
  background: #31333C;
  padding-top: 6px;
}
body > header a.logo img {
  width: 178px;
  height: 59px;
  display: block;
  margin: 0 auto;
}
body > header nav {
  display: none;
  background: #6e7076;
  background-image: url("header_bg.png");
  background-size: 8px 20px;
  padding: 5px 10px;
}
body > header nav ul, body > header nav li {
  list-style: none;
  margin: 0;
  padding: 0;
}
body > header nav > ul > li.login a, body > header nav > ul > li.logout a {
  background: #a28522;
}
body > header nav > ul > li.login a:before, body > header nav > ul > li.logout a:before {
  border-color: #a28522 #a28522 transparent transparent;
}
body > header nav > ul > li.locale a {
  margin-bottom: 0;
  background: none;
}
body > header nav > ul > li.locale a:before {
  content: none;
}
body > header nav > ul > li.locale a img {
  vertical-align: -0.05em;
  margin-left: 4px;
}
body > header nav a, body > header nav a:link, body > header nav a:visited, body > header nav span {
  display: block;
  line-height: 34px;
  color: #fff;
  letter-spacing: 0.07em;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  font-size: 2.4rem;
  background-color: #31333C;
  margin-bottom: 5px;
  margin-left: 38px;
  padding-right: 38px;
  position: relative;
}
body > header nav a:before, body > header nav a:link:before, body > header nav a:visited:before, body > header nav span:before {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: -34px;
  top: 0;
  border-width: 17px;
  border-style: solid;
  border-color: #31333C #31333C transparent transparent;
}
body > header nav a.active, body > header nav a:link.active, body > header nav a:visited.active, body > header nav span.active {
  color: #a28522;
}
body > header .show-menu {
  display: block;
  width: 40px;
  height: 40px;
  position: absolute;
  top: 35px;
  right: 5px;
  text-indent: -99999px;
  background-image: url("nav.svg");
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}
body > header input[type=checkbox] {
  display: none;
}
body > header input[type=checkbox]:checked ~ nav {
  display: block;
}

@media (max-width: 1023px) {
  body > header nav span {
    background: none;
  }
  body > header nav span:before {
    content: none;
  }
}
@media (min-width: 1024px) {
  body > header {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 5;
    border-top: 4px solid #31333C;
  }
  body > header:before, body > header:after {
    display: block;
    position: absolute;
    z-index: 20;
    top: 25px;
    content: " ";
    width: calc(50% - 98px);
    height: 1px;
    background: #a28522;
  }
  body > header:before {
    left: 0;
  }
  body > header:after {
    right: 0;
  }
  body > header a.logo {
    width: 227px;
    height: 98px;
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    color: transparent;
    padding-top: 9px;
  }
  body > header a.logo img {
    position: relative;
    left: -2px;
    z-index: 2;
    width: 235px;
    height: 78px;
  }
  body > header a.logo:before, body > header a.logo:after {
    content: " ";
    position: absolute;
    z-index: 1;
    width: 0;
    height: 0;
    top: 0;
    border: 49px solid;
  }
  body > header a.logo:before {
    left: -98px;
    border-color: #31333C #31333C transparent transparent;
  }
  body > header a.logo:after {
    right: -98px;
    border-color: #31333C transparent transparent #31333C;
  }
  body > header nav {
    display: block;
    height: 78px;
    padding: 0;
  }
  body > header nav > ul > li {
    top: 26px;
    position: absolute;
    z-index: 1;
  }
  body > header nav > ul > li.products {
    margin-left: -430px;
  }
  body > header nav > ul > li.distributors {
    margin-left: -290px;
  }
  body > header nav > ul > li.news {
    top: 64px;
    margin-left: -252px;
  }
  body > header nav > ul > li.about {
    margin-right: -290px;
  }
  body > header nav > ul > li.contact {
    margin-right: -430px;
  }
  body > header nav > ul > li.locale {
    top: 64px;
    margin-right: -252px;
  }
  body > header nav > ul > li.mobile {
    display: none;
  }
  body > header nav > ul > li.products, body > header nav > ul > li.distributors, body > header nav > ul > li.news {
    left: 50%;
  }
  body > header nav > ul > li.about, body > header nav > ul > li.contact, body > header nav > ul > li.locale {
    right: 50%;
  }
  body > header nav > ul > li.about a:before, body > header nav > ul > li.contact a:before, body > header nav > ul > li.locale a:before {
    border-color: transparent #31333C #31333C transparent;
  }
  body > header nav > ul > li.about a:after, body > header nav > ul > li.contact a:after, body > header nav > ul > li.locale a:after {
    border-color: #31333C transparent transparent #31333C;
  }
  body > header nav > ul > li.products {
    z-index: 2;
  }
  body > header nav > ul > li.distributors {
    z-index: 3;
  }
  body > header nav > ul > li.locale a {
    background: #31333C;
  }
  body > header nav > ul > li.locale a:before {
    content: " ";
  }
  body > header nav > ul > li.login, body > header nav > ul > li.logout {
    top: 0;
    right: 0;
  }
  body > header nav > ul > li.login a, body > header nav > ul > li.logout a {
    padding: 0 20px;
    height: 26px;
    line-height: 26px;
  }
  body > header nav > ul > li.login a:before, body > header nav > ul > li.logout a:before {
    left: -26px;
    border-width: 13px;
    border-color: transparent #a28522 #a28522 transparent;
  }
  body > header nav > ul > li.login a:hover, body > header nav > ul > li.logout a:hover {
    color: #fff;
  }
  body > header nav > ul > li:hover span {
    color: #a28522;
  }
  body > header nav a, body > header nav a:link, body > header nav a:visited, body > header nav span {
    display: block;
    width: 95px;
    margin: 0;
    padding-right: 0;
    letter-spacing: 0.05em;
  }
  body > header nav a:after, body > header nav a:link:after, body > header nav a:visited:after, body > header nav span:after {
    content: " ";
    position: absolute;
    width: 0;
    height: 0;
    right: -34px;
    top: 0;
    border: 17px solid;
    border-color: transparent transparent #31333C #31333C;
  }
  body > header nav a:hover, body > header nav a:link:hover, body > header nav a:visited:hover, body > header nav span:hover {
    color: #a28522;
  }
  body > header nav span {
    cursor: default;
  }
  body > header nav ul ul {
    display: none;
    padding-top: 10px;
  }
  body > header nav ul ul a, body > header nav ul ul a:link, body > header nav ul ul a:visited, body > header nav ul ul span {
    width: auto;
    text-align: left;
    text-transform: none;
    padding: 0 30px 0 60px;
  }
  body > header nav ul ul a:before, body > header nav ul ul a:after, body > header nav ul ul a:link:before, body > header nav ul ul a:link:after, body > header nav ul ul a:visited:before, body > header nav ul ul a:visited:after, body > header nav ul ul span:before, body > header nav ul ul span:after {
    content: none;
  }
  body > header nav ul ul li {
    border-bottom: 1px solid #a28522;
    border-left: 1px solid #a28522;
    border-right: 1px solid #a28522;
  }
  body > header nav ul ul li:first-of-type {
    border-top: 1px solid #a28522;
  }
  body > header nav ul ul li a {
    background-repeat: no-repeat;
    background-position: 15px -3px;
    background-size: 30px auto;
  }
  body > header nav ul ul li a:hover, body > header nav ul ul li a:active {
    color: #a28522;
    background-position: 15px -43px;
  }
  body > header nav ul ul li.active a {
    color: #a28522;
    background-position: 15px -43px;
  }
  body > header nav ul ul li:nth-of-type(1) {
    background-color: #31333C;
  }
  body > header nav ul ul li:nth-of-type(1) a {
    background-image: url("flightbags.svg");
  }
  body > header nav ul ul li:nth-of-type(2) a {
    background-image: url("kneeboards.svg");
  }
  body > header nav ul ul li:nth-of-type(3) a {
    background-image: url("covers.svg");
  }
  body > header nav ul ul li:nth-of-type(4) a {
    background-image: url("useful.svg");
  }
  body > header nav ul ul li:nth-of-type(5) a {
    background-image: url("textile.svg");
  }
  body > header nav ul ul li:nth-of-type(6) a {
    background-image: url("headset.svg");
  }
  body > header nav li:hover ul {
    display: block;
  }
  body > header .show-menu {
    display: none;
  }
  div.main-content {
    margin: 0 auto;
    max-width: 1220px;
  }
  div.main-content div.column {
    display: inline-block;
    vertical-align: top;
    margin-right: 40px;
  }
}
@media (min-width: 1290px) {
  div.container {
    max-width: 1290px;
    margin: 0 auto;
  }
}
@media (min-width: 1440px) {
  body > header nav > ul > li.products {
    margin-left: -570px;
  }
  body > header nav > ul > li.distributors {
    margin-left: -430px;
  }
  body > header nav > ul > li.news {
    top: 26px;
    margin-left: -290px;
  }
  body > header nav > ul > li.about {
    margin-right: -290px;
  }
  body > header nav > ul > li.contact {
    margin-right: -430px;
  }
  body > header nav > ul > li.locale {
    top: 26px;
    margin-right: -570px;
  }
}
nav.haendler, nav.admin-navi {
  background: #31333C;
  margin-top: 10px;
  border-bottom: 1px solid #a28522;
}
nav.haendler a, nav.haendler a:link, nav.haendler a:visited, nav.admin-navi a, nav.admin-navi a:link, nav.admin-navi a:visited {
  border-top: 1px solid #a28522;
  display: block;
  padding: 2px 20px 5px 20px;
  color: #fff;
  text-decoration: none;
}
nav.haendler a:hover, nav.haendler a:active, nav.haendler a.active, nav.haendler a:link:hover, nav.haendler a:link:active, nav.haendler a:link.active, nav.haendler a:visited:hover, nav.haendler a:visited:active, nav.haendler a:visited.active, nav.admin-navi a:hover, nav.admin-navi a:active, nav.admin-navi a.active, nav.admin-navi a:link:hover, nav.admin-navi a:link:active, nav.admin-navi a:link.active, nav.admin-navi a:visited:hover, nav.admin-navi a:visited:active, nav.admin-navi a:visited.active {
  color: #a28522;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
  letter-spacing: 0.03em;
}

.gold {
  color: #a28522;
}

a.back-to-top {
  display: block;
  position: fixed;
  z-index: 1000;
  bottom: 50px;
  right: 30px;
  margin: 0;
  width: 45px;
  height: 45px;
  overflow: hidden;
  text-indent: -2000px;
  transition: opacity 1s;
  background-image: url("plane_top.svg");
  background-repeat: no-repeat;
  background-position: center top;
  background-size: auto 90px;
}
a.back-to-top:hover {
  background-position: center bottom;
}

main {
  flex: 1;
}
main h1, main h2, main h3, main h4, main h5, main h6, main p, main form {
  margin-left: 15px;
  margin-right: 15px;
}
main form h1, main form h2, main form h3, main form h4, main form h5, main form h6, main form p {
  margin-left: 0;
  margin-right: 0;
}
main h2, main h3, main h4, main h5, main h6 {
  color: #a28522;
}
main a:link, main a:visited {
  color: #a28522;
}
main a:active, main a:hover {
  color: #000000;
}
main a.more {
  font-size: 3.2rem;
  text-transform: uppercase;
  text-decoration: none;
  padding-left: 40px;
  background-image: url("link_more.svg");
  background-repeat: no-repeat;
  background-position: left center;
}
main img {
  max-width: 100%;
}
main header {
  display: block;
  position: relative;
  background-size: cover;
  background-position: center;
  padding-top: 85px;
  padding-bottom: 5px;
}
main header:before {
  content: " ";
  position: absolute;
  z-index: -1;
  left: 0;
  right: 0;
  bottom: -5px;
  border: 1px solid #a28522;
}
main header h1 {
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 50px;
  background-image: url("plane_white.svg");
  background-repeat: no-repeat;
  background-position: left 0.3em;
}

@media (min-width: 1325px) {
  main header h1 {
    width: 1245px;
    margin: 0 auto;
  }
}
main header {
  background-image: url("header_content_pages.jpg");
  /* Chrome 66+, Edge 79+, Opera 53+, Android Brower 80+ */
  /* FF 66+ */
}
@media (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
  @supports (background-image: -webkit-image-set(url("header_content_pages.webp") 1x)) {
    main header {
      background-image: url("header_content_pages.webp");
    }
  }
}
@supports (flex-basis: max-content) and (-moz-appearance: meterbar) {
  main header {
    background-image: url("header_content_pages.webp");
  }
}

main.colums {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 30px;
}
main.colums div.spalte-links {
  display: none;
  width: 230px;
  margin-right: 30px;
}
main.colums div.spalte-rechts {
  margin-bottom: 30px;
}
main.colums div.spalte-rechts h1 {
  margin: 0 0 0.5em 0;
  padding-left: 15px;
  color: #fff;
  background: #a28522;
}
main.colums nav.sub {
  background: #31333C;
  font-size: 2.4rem;
}
main.colums nav.sub ul {
  margin: 0;
  padding: 0;
}
main.colums nav.sub ul li {
  list-style: none;
  margin: 0;
  padding: 0;
}
main.colums nav.sub ul li.open ul {
  display: block;
}
main.colums nav.sub ul a {
  display: block;
  line-height: 1.2em;
  text-decoration: none;
  color: #fff;
}
main.colums nav.sub ul a:hover, main.colums nav.sub ul a.active {
  color: #a28522;
}
main.colums nav.sub > ul > li {
  border-bottom: 1px solid #a28522;
}
main.colums nav.sub > ul > li > a {
  padding-left: 55px;
  line-height: 1.7em;
  background-repeat: no-repeat;
  background-position: 15px 0;
  background-size: 30px auto;
}
main.colums nav.sub > ul > li:hover > a, main.colums nav.sub > ul > li.open > a {
  color: #a28522;
  background-position: 15px -40px;
}
main.colums nav.sub > ul > li:nth-of-type(1) > a {
  background-image: url("flightbags.svg");
}
main.colums nav.sub > ul > li:nth-of-type(2) > a {
  background-image: url("kneeboards.svg");
}
main.colums nav.sub > ul > li:nth-of-type(3) > a {
  background-image: url("covers.svg");
}
main.colums nav.sub > ul > li:nth-of-type(4) > a {
  background-image: url("useful.svg");
}
main.colums nav.sub > ul > li:nth-of-type(5) > a {
  background-image: url("textile.svg");
}
main.colums nav.sub > ul > li:nth-of-type(6) > a {
  background-image: url("headset.svg");
}
main.colums nav.sub ul ul {
  display: none;
  padding: 0 0.5em 0 1.85em;
  margin-bottom: 0.5em;
}
main.colums nav.sub ul ul li {
  list-style: square;
  margin-bottom: 0.2em;
  font-size: 0.9em;
  color: #fff;
}
main.colums nav.sub ul ul li:hover, main.colums nav.sub ul ul li.active {
  color: #a28522;
}
main.colums nav.sub ul ul li:hover a, main.colums nav.sub ul ul li.active a {
  color: #a28522;
}
main.colums nav.sub ul ul a {
  display: inline;
}

a.back {
  margin-left: 20px;
  margin-bottom: 40px;
  text-decoration: none;
}
a.back:before {
  content: "« ";
}

@media (min-width: 1024px) {
  main {
    padding-top: 112px;
  }
  main header {
    background-position: center bottom;
    margin-top: -30px;
    padding-top: 150px;
    padding-bottom: 15px;
  }
  body.site.index main,
  body.site.news main {
    padding-top: 0;
  }
  main.colums {
    padding-top: 150px;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    background-image: url("bg.jpg");
    /* Chrome 66+, Edge 79+, Opera 53+, Android Brower 80+ */
    /* FF 66+ */
  }
  main.colums div.spalte-links {
    display: block;
  }
}
@media (min-width: 1024px) and (min-width: 1536px) {
  main.colums.flugtaschen, main.colums.flightbags, main.colums.kniebretter, main.colums.kneeboards, main.colums.schutzhuellen, main.colums.covers, main.colums.nuetzliches, main.colums.useful, main.colums.bekleidung, main.colums.textiles {
    background-position: bottom left, center;
    background-size: min(450px, (100vw - 990px) / 2), cover;
  }
  main.colums.flugtaschen, main.colums.flightbags {
    background-image: url("category_bg_flightbags.png"), url("bg.jpg");
  }
  main.colums.kniebretter, main.colums.kneeboards {
    background-image: url("category_bg_kneeboards.png"), url("bg.jpg");
  }
  main.colums.schutzhuellen, main.colums.covers {
    background-image: url("category_bg_covers.png"), url("bg.jpg");
  }
  main.colums.nuetzliches, main.colums.useful {
    background-image: url("category_bg_useful.png"), url("bg.jpg");
  }
  main.colums.bekleidung, main.colums.textiles {
    background-image: url("category_bg_textiles.png"), url("bg.jpg");
  }
}
@media (min-width: 1024px) and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
  @supports (background-image: -webkit-image-set(url("header_content_pages.webp") 1x)) {
    main.colums {
      background-image: url("bg.webp");
    }
    @media (min-width: 1024px) and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) and (min-width: 1536px) {
      main.colums.flugtaschen, main.colums.flightbags, main.colums.kniebretter, main.colums.kneeboards, main.colums.schutzhuellen, main.colums.covers, main.colums.nuetzliches, main.colums.useful, main.colums.bekleidung, main.colums.textiles {
        background-position: bottom left, center;
        background-size: min(450px, (100vw - 990px) / 2), cover;
      }
      main.colums.flugtaschen, main.colums.flightbags {
        background-image: url("category_bg_flightbags.webp"), url("bg.webp");
      }
      main.colums.kniebretter, main.colums.kneeboards {
        background-image: url("category_bg_kneeboards.webp"), url("bg.webp");
      }
      main.colums.schutzhuellen, main.colums.covers {
        background-image: url("category_bg_covers.webp"), url("bg.webp");
      }
      main.colums.nuetzliches, main.colums.useful {
        background-image: url("category_bg_useful.webp"), url("bg.webp");
      }
      main.colums.bekleidung, main.colums.textiles {
        background-image: url("category_bg_textiles.webp"), url("bg.webp");
      }
    }
  }
}
@media (min-width: 1024px) {
  @supports (flex-basis: max-content) and (-moz-appearance: meterbar) {
    main.colums {
      background-image: url("bg.webp");
    }
    @media (min-width: 1024px) and (min-width: 1536px) {
      main.colums.flugtaschen, main.colums.flightbags, main.colums.kniebretter, main.colums.kneeboards, main.colums.schutzhuellen, main.colums.covers, main.colums.nuetzliches, main.colums.useful, main.colums.bekleidung, main.colums.textiles {
        background-position: bottom left, center;
        background-size: min(450px, (100vw - 990px) / 2), cover;
      }
      main.colums.flugtaschen, main.colums.flightbags {
        background-image: url("category_bg_flightbags.webp"), url("bg.webp");
      }
      main.colums.kniebretter, main.colums.kneeboards {
        background-image: url("category_bg_kneeboards.webp"), url("bg.webp");
      }
      main.colums.schutzhuellen, main.colums.covers {
        background-image: url("category_bg_covers.webp"), url("bg.webp");
      }
      main.colums.nuetzliches, main.colums.useful {
        background-image: url("category_bg_useful.webp"), url("bg.webp");
      }
      main.colums.bekleidung, main.colums.textiles {
        background-image: url("category_bg_textiles.webp"), url("bg.webp");
      }
    }
  }
}
@media (min-width: 768px) {
  main.colums div.spalte-rechts {
    width: 720px;
  }
  main.colums div.spalte-rechts h1 {
    font-size: 1.1em;
    height: 36px;
    line-height: 36px;
    padding: 0 15px;
    display: inline-block;
    position: relative;
  }
  main.colums div.spalte-rechts h1:after {
    content: " ";
    width: 720px;
    max-width: 95vw;
    height: 1px;
    background: #a28522;
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
  }
  main.colums div.spalte-rechts h1:before {
    content: " ";
    position: absolute;
    width: 0;
    height: 0;
    right: -36px;
    top: 0;
    border: 18px solid;
    border-color: transparent transparent #a28522 #a28522;
  }
  a.back {
    margin-left: 0;
  }
}
.no-br {
  white-space: nowrap;
}

p.download-agb {
  float: right;
  font-weight: bold;
  margin-top: 5px;
}
p.download-agb a:hover {
  color: #a28522;
}
p.download-agb a img {
  vertical-align: bottom;
  margin-right: 10px;
}

footer {
  display: block;
  flex: none;
  padding: 10px;
  color: #ffffff;
  background: #31333C;
}
footer nav {
  padding-bottom: 10px;
}
footer a, footer a:link, footer a:visited {
  color: #ffffff;
  text-decoration: none;
  text-transform: uppercase;
  display: block;
  text-align: center;
  margin-bottom: 5px;
}
footer a:hover, footer a:active, footer a.active, footer a:link:hover, footer a:link:active, footer a:link.active, footer a:visited:hover, footer a:visited:active, footer a:visited.active {
  color: #a28522;
}
footer:after {
  content: " ";
  display: block;
  height: 0;
  clear: both;
}
footer span.copy {
  float: right;
  background: #a28522;
  font-size: 1.6rem;
  padding: 0 0.5em;
  line-height: 22px;
  position: relative;
}
footer span.copy:before {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: -22px;
  top: 0;
  border-width: 11px;
  border-style: solid;
  border-color: transparent #a28522 #a28522 transparent;
}
footer span.copy:after {
  display: block;
  content: " ";
  width: 100%;
  height: 1px;
  margin-left: -100%;
  background: #a28522;
}

@media (min-width: 870px) {
  footer {
    padding: 0 0 10px 0;
    display: flex;
    align-items: center;
  }
  footer nav {
    flex: 1;
    text-align: right;
    margin-right: 60px;
    padding-bottom: 0;
  }
  footer a, footer a:link, footer a:visited, footer a:hover, footer a:active {
    display: inline-block;
    margin-bottom: 2px;
    margin-left: 20px;
  }
  footer span.copy {
    flex: none;
    float: none;
    font-size: 2rem;
    line-height: 30px;
  }
  footer span.copy:before {
    left: -30px;
    border-width: 15px;
  }
  footer span.copy:after {
    width: 100vw;
    margin-left: -100vw;
  }
}
section.partner h3 {
  margin-bottom: 0;
}
section.partner p {
  margin-top: 0;
}

main.popup, main.sizechart {
  width: auto;
  height: auto;
  margin: 0 auto;
  position: relative;
  padding: 20px;
  overflow: auto;
  background: #ffffff;
}
main.popup h1, main.popup h2, main.popup h3, main.sizechart h1, main.sizechart h2, main.sizechart h3 {
  margin: 0;
}

main.sizechart table {
  margin: 0 auto;
  border-collapse: collapse;
}
main.sizechart table td, main.sizechart table th {
  border: 1px solid #a28522;
  padding: 0.3em;
  text-align: center;
}
main.sizechart table th {
  background-color: #a28522;
  color: #FFFFFF;
}
main.sizechart table th.eck {
  background: none;
  border: none;
  color: #000000;
}

main.popup header {
  background-image: none;
  padding: 0;
}
main.popup header h1 {
  color: #a28522;
  padding-left: 0;
  background-image: none;
}

.button, nav.admin a, input[type=submit], textarea[type=submit], .small_button {
  text-decoration: none;
  border: none;
  display: inline-block;
  padding: 0.2em 0.5em;
  color: #fff;
  background: #a28522;
  font-size: 2.4rem;
  cursor: pointer;
}

.small_button {
  font-size: 1.8rem;
}

.add_button, .delete_button, a.remove_nested_fields, a.add_nested_fields, div.shopping_cart_form a.add_variant {
  display: inline-block;
  background: #31333C;
  line-height: 1.4em;
  text-decoration: none;
  color: #fff;
  background-image: url("plus.png");
  background-repeat: no-repeat;
  background-position: 8px center;
  padding: 0 15px 0 30px;
  border-radius: 12px;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes animatedClouds {
  from {
    background-position: 0vw 0vh, 0vw 0vh;
  }
  to {
    background-position: -6vw -41vh, 29vw -41vh;
  }
}
@keyframes animatedPlane {
  from {
    opacity: 1;
    background-size: 100%;
  }
  50% {
    opacity: 1;
  }
  to {
    opacity: 0;
    background-size: 40%;
  }
}
.slider {
  position: relative;
  width: 100%;
  height: calc(100vh - 123px);
  overflow: hidden;
  z-index: 0;
  background-image: url("home_bg.jpg");
  background-size: cover;
  background-position: center;
  /* Chrome 66+, Edge 79+, Opera 53+, Android Brower 80+ */
  /* FF 66+ */
}
.slider.active {
  z-index: 10;
}
.slider div.slides {
  position: relative;
  height: 100%;
  background-repeat: no-repeat;
  background-image: url("home_clouds_left.png"), url("home_clouds_right.png");
  background-size: 100vw, 100vw;
  background-position: 0vw 0vh, 0vw 0vh;
  animation: animatedClouds 100s ease-in-out infinite;
}
.slider div.slides:before {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  content: " ";
  opacity: 0;
  background-repeat: no-repeat;
  background-image: url("home_plane.png");
  background-size: 40%;
  background-position: top left;
  animation: animatedPlane 30s ease-out;
}
@media (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
  @supports (background-image: -webkit-image-set(url("header_content_pages.webp") 1x)) {
    .slider {
      background-image: url("home_bg.webp");
    }
    .slider div.slides {
      background-image: url("home_clouds_left.webp"), url("home_clouds_right.webp");
    }
    .slider div.slides:before {
      background-image: url("home_plane.webp");
    }
  }
}
@supports (flex-basis: max-content) and (-moz-appearance: meterbar) {
  .slider {
    background-image: url("home_bg.webp");
  }
  .slider div.slides {
    background-image: url("home_clouds_left.webp"), url("home_clouds_right.webp");
  }
  .slider div.slides:before {
    background-image: url("home_plane.webp");
  }
}
.slider div.slide {
  position: absolute;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 2;
}
.slider div.slide .content {
  height: 100%;
  min-height: 580px;
  margin: 0 auto;
  max-width: 910px;
  display: flex;
}
.slider div.slide .content > img, .slider div.slide .content > picture, .slider div.slide .content > a {
  flex: none;
  align-self: center;
}
.slider div.slide .content .text {
  flex: 1;
  align-self: center;
}
.slider div.slide.active {
  opacity: 1;
  z-index: 3;
}
.slider div.slide.fadein {
  animation-duration: 1.5s;
  animation-name: fadein;
}
.slider div.slide.fadeout {
  animation-duration: 0.5s;
  animation-name: fadeout;
}
.slider div.slide.landing h1 {
  color: #a28522;
  margin-top: 0;
}
.slider div.slide.landing .content {
  display: block;
  position: relative;
  margin: auto;
}
.slider div.slide.landing .content aside.twenty-years, .slider div.slide.landing .content .text {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding-bottom: 200px;
}
.slider div.slide.landing .content aside.twenty-years {
  width: 265px;
  margin-left: 0;
}
.slider div.slide.landing .content aside.twenty-years img {
  max-width: 100%;
}
.slider div.slide.landing .content .text {
  margin-left: 325px;
}
.slider div.slide.landing .images {
  display: flex;
  clear: left;
  justify-content: space-between;
  align-items: center;
  min-height: 0;
  position: absolute;
  bottom: calc(3vh - 40px);
}
.slider div.slide.landing .images img {
  flex: none;
}
.slider div.slide.landing .images img.flightbags {
  width: 297px;
}
.slider div.slide.landing .images img.useful {
  width: 172px;
}
.slider div.slide.landing .images img.kneeboards {
  width: 192px;
  margin-bottom: -10px;
}
.slider div.slide.landing .images img.covers {
  width: 212px;
  margin-bottom: -80px;
}
.slider div.slide.landing .images img.textiles {
  width: 344px;
}
.slider div.slide.news img, .slider div.slide.news picture {
  max-width: 450px;
  max-width: min(530px, 50vw);
  max-height: 450px;
  max-height: min(610px, 55vh);
  margin-right: 50px;
}
.slider .controls {
  position: absolute;
  margin: auto 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 0px;
}
.slider .controls button {
  position: absolute;
  top: -23px;
  z-index: 3;
  border: 0;
  padding: 0;
  background-color: rgba(0, 0, 0, 0);
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 90px auto;
  background-image: url("slideshow_nav.svg");
  width: 45px;
  height: 45px;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  cursor: pointer;
}
.slider .controls button.prev {
  left: 30px;
}
.slider .controls button.next {
  right: 30px;
  transform: rotate(0.5turn);
}
.slider .controls button:hover {
  background-position: right center;
}
.slider .dots {
  position: absolute;
  bottom: 15px;
  margin: 0 auto;
  left: 0;
  right: 0;
  z-index: 3;
  text-align: center;
}
.slider .dots a {
  display: inline-block;
  background: #31333C;
  margin: 0 3px;
  width: 10px;
  height: 10px;
  text-decoration: none;
  overflow: hidden;
  text-indent: 101%;
}
.slider .dots a.active {
  background: #a28522;
}

@media screen and (max-width: 1023px) {
  .slider {
    height: auto;
    overflow: visible;
    background-image: none;
    position: static;
    /* Chrome 66+, Edge 79+, Opera 53+, Android Brower 80+ */
    /* FF 66+ */
  }
  .slider div.slides {
    position: static;
    height: auto;
    background-image: none;
    animation: none;
  }
  .slider div.slides:before {
    content: none;
  }
}
@media screen and (max-width: 1023px) and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
  @supports (background-image: -webkit-image-set(url("header_content_pages.webp") 1x)) {
    .slider {
      background-image: none;
    }
    .slider div.slides {
      background-image: none;
    }
  }
}
@media screen and (max-width: 1023px) {
  @supports (flex-basis: max-content) and (-moz-appearance: meterbar) {
    .slider {
      background-image: none;
    }
    .slider div.slides {
      background-image: none;
    }
  }
  .slider div.slide {
    position: static;
    height: auto;
    opacity: 1;
  }
  .slider div.slide .content {
    height: auto;
    display: block;
  }
  .slider div.slide.fadein {
    animation: none;
  }
  .slider div.slide.fadeout {
    animation: none;
  }
  .slider div.slide.landing .content {
    position: static;
  }
  .slider div.slide.landing .content aside.twenty-years, .slider div.slide.landing .content .text {
    position: static;
    transform: none;
    padding-bottom: 0;
  }
  .slider div.slide.landing .content aside.twenty-years {
    background-repeat: no-repeat;
    background-image: url("home_bg_mobile.jpg");
    /* Chrome 66+, Edge 79+, Opera 53+, Android Brower 80+ */
    /* FF 66+ */
    background-size: cover;
    background-position: top center;
    margin-bottom: 30px;
    text-align: center;
    width: 100%;
    height: 44.5vw;
  }
}
@media screen and (max-width: 1023px) and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
  @supports (background-image: -webkit-image-set(url("header_content_pages.webp") 1x)) {
    .slider div.slide.landing .content aside.twenty-years {
      background-image: url("home_bg_mobile.webp");
    }
  }
}
@media screen and (max-width: 1023px) {
  @supports (flex-basis: max-content) and (-moz-appearance: meterbar) {
    .slider div.slide.landing .content aside.twenty-years {
      background-image: url("home_bg_mobile.webp");
    }
  }
  .slider div.slide.landing .content aside.twenty-years img {
    max-height: 27vw;
    margin-top: 13vw;
  }
  .slider div.slide.landing .content .text {
    margin-left: 0;
  }
  .slider div.slide.landing .images {
    position: static;
  }
  .slider div.slide.news {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .slider div.slide.news a {
    margin-left: 20px;
  }
  .slider .news:before {
    content: "News";
    display: block;
    padding: 20px;
    font-size: 2em;
    color: #a28522;
  }
  .slider .news ~ .news:before {
    content: none;
  }
  .slider .controls {
    display: none;
  }
  .slider .dots {
    display: none;
  }
}
@media screen and (max-width: 767px) {
  .slider div.slide.landing .content .images {
    flex-wrap: wrap;
    padding: 0 20px;
    justify-content: space-evenly;
  }
}
@media screen and (max-width: 428px) {
  .slider div.slide.news .content img, .slider div.slide.news .content picture {
    max-width: 90%;
    margin-right: 0;
  }
}
@media screen and (min-width: 1024px) {
  body.site.index #slideshow,
  body.site.news #slideshow {
    margin-top: 82px;
  }
}
.button, nav.admin a, input[type=submit], textarea[type=submit], .small_button {
  text-decoration: none;
  border: none;
  display: inline-block;
  padding: 0.2em 0.5em;
  color: #fff;
  background: #a28522;
  font-size: 2.4rem;
  cursor: pointer;
}

.small_button {
  font-size: 1.8rem;
}

.add_button, .delete_button, a.remove_nested_fields, a.add_nested_fields, div.shopping_cart_form a.add_variant {
  display: inline-block;
  background: #31333C;
  line-height: 1.4em;
  text-decoration: none;
  color: #fff;
  background-image: url("plus.png");
  background-repeat: no-repeat;
  background-position: 8px center;
  padding: 0 15px 0 30px;
  border-radius: 12px;
}

form {
  margin-bottom: 2em;
}
form:after {
  content: " ";
  display: block;
  height: 0;
  clear: both;
}
form .error, form .error_notification {
  color: red;
}
form .hint {
  font-size: 0.9em;
  color: #a28522;
  margin-right: 1rem;
}
form.button_to {
  margin: 0;
  display: inline-block;
}

body.password_resets form {
  margin-top: 1.5em;
}

div.input {
  margin-bottom: 0.5em;
}

.fields:after {
  content: " ";
  display: block;
  height: 0;
  clear: both;
}

div.actions {
  margin-top: 0.5em;
}

.botr {
  display: none;
}

label {
  display: block;
}
label.required abbr {
  color: red;
  text-decoration: none;
}

.input.pilotenpool_flyself label {
  display: inline;
  margin-right: 1em;
}

fieldset {
  border: none;
  margin: 0 0 1em 0;
  padding: 0;
}

legend {
  font-weight: bold;
  color: #a28522;
  margin-bottom: 0.5em;
}

input, textarea {
  font-family: "MisoRegular", Arial, Helvetica, sans-serif;
  background: #ece7d3;
  border: none;
  width: 100%;
  box-sizing: border-box;
  font-size: 2.4rem;
  padding: 0.2em 0.5em;
}
input[type=checkbox], input[type=radio], textarea[type=checkbox], textarea[type=radio] {
  width: auto;
}
input[type=submit], textarea[type=submit] {
  -webkit-appearance: none;
}

select {
  width: 100%;
}

input[type=checkbox] {
  height: 0;
  width: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  visibility: hidden;
}
input[type=checkbox] + label {
  width: auto !important;
  display: inline-block;
  text-indent: -35px;
  padding-left: 35px;
}
input[type=checkbox] + label:before {
  vertical-align: text-top;
  content: " ";
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 12px;
  border: 1px solid #a28522;
  background-color: rgba(162, 133, 34, 0.2);
}
input[type=checkbox]:checked + label:before {
  background-image: url("check.svg");
  background-repeat: no-repeat;
  background-size: 16px auto;
  background-position: center;
}

span.radio {
  padding-left: 30px;
  position: relative;
  z-index: 1;
}
span.radio label {
  position: relative;
  z-index: 2;
  margin-left: -30px;
  padding-left: 30px;
}
span.radio:before {
  content: " ";
  display: block;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  border: 1px solid #a28522;
  border-radius: 11px;
  background-color: rgba(162, 133, 34, 0.2);
}
span.radio input[type=radio] {
  height: 0;
  width: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  visibility: hidden;
}
span.radio input[type=radio]:checked + label:before {
  content: " ";
  display: block;
  width: 10px;
  height: 10px;
  position: absolute;
  top: 6px;
  left: 6px;
  vertical-align: middle;
  border-radius: 6px;
  background-color: #a28522;
}

div[class$=terms_of_service] {
  margin-top: 1.5em;
}

#recommend .recipient {
  position: relative;
  border-bottom: 2px solid #a28522;
  padding-bottom: 0.5em;
  margin-bottom: 0.5em;
}
#recommend .recipient:after {
  content: " ";
  display: block;
  height: 0;
  clear: both;
}
#recommend .recipient .remove_nested_fields {
  float: right;
}

.button, nav.admin a, .small_button, input[type=submit], textarea[type=submit] {
  text-decoration: none;
  border: none;
  display: inline-block;
  padding: 0.2em 0.5em;
  color: #fff;
  background: #a28522;
  font-size: 2.4rem;
  cursor: pointer;
}

.small_button {
  font-size: 1.8rem;
}

.add_button, .delete_button, a.remove_nested_fields, a.add_nested_fields, div.shopping_cart_form a.add_variant {
  display: inline-block;
  background: #31333C;
  line-height: 1.4em;
  text-decoration: none;
  color: #fff;
  background-image: url("plus.png");
  background-repeat: no-repeat;
  background-position: 8px center;
  padding: 0 15px 0 30px;
  border-radius: 12px;
}

ol#products {
  margin: 0;
  padding: 0;
}
ol#products > li {
  position: relative;
  list-style: none;
  margin: 0;
  margin-bottom: 10px;
  border-bottom: 2px solid #a28522;
  padding-bottom: 20px;
}
ol#products > li:after {
  content: " ";
  display: block;
  height: 0;
  clear: both;
}
ol#products > li img {
  margin: 20px 0;
  padding: 20px;
  box-sizing: border-box;
}
ol#products > li img.special_offer {
  float: right;
  margin-top: 5px;
  margin-right: 10px;
}
ol#products > li h2 {
  margin-top: 25px;
}
ol#products a {
  text-decoration: none;
}
ol#products a.more {
  float: right;
  margin-right: 20px;
}
ol#products.search a {
  width: auto !important;
}
ol#products.search h2 {
  margin-left: 20px;
}
ol#products.search picture img {
  width: 180px;
  height: 180px;
  object-fit: contain;
}

#search-form {
  display: flex;
  margin-bottom: 1em;
}
#search-form input[type=submit] {
  width: auto;
}

@media (min-width: 768px) {
  ol#products > li {
    margin-bottom: 10px;
    border-bottom: 1px solid #a28522;
    padding-bottom: 20px;
  }
  ol#products > li:after {
    content: " ";
    display: block;
    height: 0;
    clear: both;
  }
  ol#products > li img {
    display: block;
    margin: 20px auto;
    padding: 0;
  }
  ol#products > li h2 {
    font-weight: bold;
  }
  ol#products > li p, ol#products > li h2, ol#products > li .availability {
    margin-left: 350px;
    padding-right: 30px;
  }
  ol#products > li .availability, ol#products > li .admin {
    clear: right;
    padding-top: 1rem;
  }
  ol#products a.more {
    margin-right: 0;
  }
  ol#products > li > a:first-of-type {
    display: block;
    width: 320px;
    margin-right: 30px;
    float: left;
  }
}
#product-detail {
  border-bottom: 1px solid #a28522;
}
#product-detail:after {
  content: " ";
  display: block;
  height: 0;
  clear: both;
}

@media (min-width: 768px) {
  #product-detail .row {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
}
div.images {
  overflow: visible;
}
div.images .main {
  padding: 20px 0;
  text-align: center;
}
div.images .main img {
  max-width: 90vw;
}

#more-pictures {
  padding: 0;
  list-style: none;
  margin: 20px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
#more-pictures li {
  flex: none;
  padding: 0;
  margin: 0 10px 10px 0;
}
#more-pictures li picture {
  transition: opacity 0.5s;
  opacity: 0.4;
}
#more-pictures li.active picture, #more-pictures li:hover picture {
  opacity: 1;
}
#more-pictures a {
  display: block;
}
#more-pictures img {
  max-width: 100px;
}

.downloads td.video .container {
  display: inline-block;
}

#controller-slideshow {
  margin-left: 20px;
  margin-bottom: 20px;
}

#colors {
  padding: 0;
  list-style: none;
  margin: 20px;
}
#colors li {
  float: left;
  display: block;
  padding: 2px;
  height: 23px;
  margin: 0 10px 0 0;
}
#colors li.active {
  padding: 0;
  border: 2px solid #a28522;
}
#colors:after {
  content: " ";
  display: block;
  height: 0;
  clear: both;
}

.masstabelle {
  line-height: 1em;
  text-decoration: none;
  padding: 3px 0 3px 25px;
  background-image: url("li_mt.gif");
  background-repeat: no-repeat;
  background-position: left center;
  margin-left: 20px;
}

.product-text p:first-of-type {
  margin-top: 0;
}

.product-features {
  padding: 0 20px;
}
.product-features h3, .product-features .masse {
  margin: 0 0 0.7em 0;
  padding-bottom: 0.3em;
  border-bottom: 1px solid #a28522;
}
.product-features h3 {
  color: #000;
}
.product-features p {
  margin: 0;
  color: #a28522;
}
.product-features .masse {
  color: #000;
}
.product-features .legal_hint {
  color: #000;
  font-size: 0.85em;
  width: 170px;
}

p.price {
  font-size: 1.6em;
  line-height: 1em;
  padding-bottom: 0.3em;
  border-bottom: 1px solid #a28522;
  color: #000;
}
p.price span {
  color: #a28522;
  font-weight: bold;
  white-space: nowrap;
}
p.price small {
  font-size: 0.6em;
}
p.price img.special_offer {
  float: right;
  margin-top: -9px;
}

div.availability {
  padding: 0 20px 30px 20px;
  border-bottom: 1px solid #a28522;
  margin: 1em 0;
}
div.availability p {
  margin-left: 0;
}
div.availability h3 {
  color: #000;
  margin: 0;
  border-bottom: none;
  padding-left: 20px;
  position: relative;
}
div.availability h3:before {
  content: " ";
  position: absolute;
  top: 7px;
  left: 0;
  width: 10px;
  height: 10px;
  border-radius: 5px;
}
div.availability h3.gruen:before {
  background: #7CAD2E;
}
div.availability h3.gelb:before {
  background: #FFCC00;
}
div.availability h3.rot:before {
  background: #CC0000;
}
div.availability h3 small {
  font-size: 0.7em;
}
div.availability h3 small.price {
  float: right;
  margin-top: 0.35em;
}
div.availability h3 small.available {
  display: block;
}
div.availability small {
  font-size: 1em;
}

div.shopping_cart_form {
  clear: both;
}
div.shopping_cart_form .fields {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 10px;
}
div.shopping_cart_form .fields .field {
  flex-grow: 1;
}
div.shopping_cart_form .fields .field input {
  width: auto;
}
@media (min-width: 768px) {
  #product-detail div.images .main {
    text-align: left;
    margin-left: 10px;
  }
  #product-detail div.images .main img {
    max-width: 430px;
  }
  #colors {
    margin: 20px 0;
  }
  .masstabelle {
    margin-left: 0;
  }
  #product-detail {
    position: relative;
  }
  .product-features {
    margin-top: 20px;
    box-sizing: border-box;
    width: 230px;
  }
}
nav.tools {
  font-weight: bold;
  padding: 1em 0;
}
nav.tools a {
  display: block;
  text-decoration: none;
  font-size: 0.9em;
  margin-bottom: 0.6em;
}
nav.tools a:last-of-type {
  margin-bottom: 0;
}
nav.tools a:after {
  white-space: nowrap;
  content: " »";
}

@keyframes spin_recycled {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}
aside.recycled {
  color: #a28522;
  margin-bottom: 1em;
  position: relative;
}
aside.recycled svg {
  z-index: 1;
  transition: 0.2s linear;
  transform-origin: center;
}
aside.recycled .tooltip {
  display: none;
  font-size: 0.9em;
  font-weight: bold;
  position: absolute;
  bottom: -66px;
  left: 0;
  z-index: 2;
  background: #fff;
  border: 1px solid #000;
  padding: 0.25em 0.8em;
  white-space: nowrap;
}
aside.recycled .tooltip:after {
  content: " ";
  position: absolute;
  top: -17px;
  left: 15px;
  width: 35px;
  height: 18px;
  background-image: url("icons/tooltip_arrow.svg");
  background-repeat: no-repeat;
  background-position: center;
}
aside.recycled:hover svg, aside.recycled:active svg, aside.recycled:focus svg {
  color: #000;
  transition: 1s linear;
  transform: rotate(-360deg);
}
aside.recycled:hover .tooltip, aside.recycled:active .tooltip, aside.recycled:focus .tooltip {
  display: block;
}

@media (max-width: 767px) {
  aside.recycled {
    margin-bottom: 1em;
  }
  aside.recycled .tooltip {
    display: block;
    position: relative;
    top: 10px;
    bottom: auto;
    left: 0;
  }
}
li .handle {
  cursor: move;
}

template {
  display: none;
}

nav.downloads {
  display: block;
  padding-bottom: 0.3em;
  margin-bottom: 1em;
  border-bottom: 1px solid #a28522;
}
nav.downloads a {
  display: inline-block;
  color: #fff;
  background: #a28522;
  text-decoration: none;
  padding: 0 0.3em;
  margin-bottom: 10px;
}

table.downloads {
  width: 100%;
  border-collapse: collapse;
}
table.downloads th {
  font-weight: normal;
  text-align: left;
}
table.downloads th, table.downloads td {
  padding: 0.3em 0.5em;
}
table.downloads td.trenner {
  background: #a28522;
}
table.downloads td.trenner h3 {
  color: #fff;
  margin: 0;
}
table.downloads tr {
  border-bottom: 1px solid #a28522;
}
table.downloads a {
  color: #fff;
  background: #a28522;
  text-decoration: none;
  padding: 0 0.3em;
  white-space: nowrap;
}

@media (max-width: 767px) {
  nav.downloads {
    margin-left: 20px;
    margin-right: 20px;
  }
  table.downloads thead, table.downloads tbody, table.downloads th, table.downloads td, table.downloads tr {
    display: block;
  }
  table.downloads th {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }
  table.downloads td {
    border: none;
    position: relative;
    height: 1.4em;
  }
  table.downloads td:nth-of-type(1) {
    height: auto;
  }
  table.downloads td:nth-of-type(3), table.downloads td:nth-of-type(4) {
    padding-left: 50%;
    text-align: right;
  }
  table.downloads td:before {
    position: absolute;
    top: 10px;
    left: 15px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
  }
  html[lang=de] table.downloads td:nth-of-type(3):before {
    content: "Internet";
  }
  html[lang=de] table.downloads td:nth-of-type(4):before {
    content: "Druck";
  }
  html[lang=en] table.downloads td:nth-of-type(3):before {
    content: "Internet";
  }
  html[lang=en] table.downloads td:nth-of-type(4):before {
    content: "Print";
  }
  table.downloads.products td:nth-of-type(2), table.downloads.products td:nth-of-type(3), table.downloads.products td:nth-of-type(4) {
    padding-left: 0;
    text-align: right;
  }
  html[lang=de] table.downloads td:nth-of-type(2):before {
    content: "";
  }
  html[lang=de] table.downloads td:nth-of-type(3):before {
    content: "";
  }
  html[lang=de] table.downloads td:nth-of-type(4):before {
    content: "";
  }
  html[lang=en] table.downloads td:nth-of-type(2):before {
    content: "";
  }
  html[lang=en] table.downloads td:nth-of-type(3):before {
    content: "";
  }
  html[lang=en] table.downloads td:nth-of-type(4):before {
    content: "";
  }
}
#product-video {
  margin: 0 auto;
  width: 100%;
  max-width: 980px;
  box-sizing: border-box;
  padding: 0 1rem;
  margin-bottom: 2rem;
}
#product-video h1 {
  margin-left: 0;
  margin-right: 0;
}
#product-video video {
  width: 100%;
  margin-bottom: 1rem;
}

.button, nav.admin a, .small_button, input[type=submit], textarea[type=submit] {
  text-decoration: none;
  border: none;
  display: inline-block;
  padding: 0.2em 0.5em;
  color: #fff;
  background: #a28522;
  font-size: 2.4rem;
  cursor: pointer;
}

.small_button {
  font-size: 1.8rem;
}

.add_button, .delete_button, a.remove_nested_fields, a.add_nested_fields, div.shopping_cart_form a.add_variant {
  display: inline-block;
  background: #31333C;
  line-height: 1.4em;
  text-decoration: none;
  color: #fff;
  background-image: url("plus.png");
  background-repeat: no-repeat;
  background-position: 8px center;
  padding: 0 15px 0 30px;
  border-radius: 12px;
}

.distributor_news hgroup {
  margin-left: 0;
}
.distributor_news hgroup h2 {
  margin-top: 25px;
}
.distributor_news article {
  margin-bottom: 1em;
  padding: 20px;
  border-bottom: 2px solid #a28522;
}
.distributor_news article h3 {
  margin: 0 0 0.6em 0;
}
.distributor_news article h3.gold {
  color: #a28522;
}
.distributor_news article img {
  float: right;
  margin: 0 0 15px 15px;
}
.distributor_news article p {
  margin-left: 0;
  margin-right: 0;
}
.distributor_news article:after {
  content: " ";
  display: block;
  height: 0;
  clear: both;
}

.button, nav.admin a, .small_button, input[type=submit], textarea[type=submit] {
  text-decoration: none;
  border: none;
  display: inline-block;
  padding: 0.2em 0.5em;
  color: #fff;
  background: #a28522;
  font-size: 2.4rem;
  cursor: pointer;
}

.small_button {
  font-size: 1.8rem;
}

.add_button, .delete_button, a.remove_nested_fields, a.add_nested_fields, div.shopping_cart_form a.add_variant {
  display: inline-block;
  background: #31333C;
  line-height: 1.4em;
  text-decoration: none;
  color: #fff;
  background-image: url("plus.png");
  background-repeat: no-repeat;
  background-position: 8px center;
  padding: 0 15px 0 30px;
  border-radius: 12px;
}

body.distributors.public {
  background-image: url("distributors/bg.jpg");
  background-repeat: no-repeat;
  background-position: center top;
  /* Chrome 66+, Edge 79+, Opera 53+, Android Brower 80+ */
  /* FF 66+ */
}
@media (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
  @supports (background-image: -webkit-image-set(url("header_content_pages.webp") 1x)) {
    body.distributors.public {
      background-image: url("distributors/bg.webp");
    }
  }
}
@supports (flex-basis: max-content) and (-moz-appearance: meterbar) {
  body.distributors.public {
    background-image: url("distributors/bg.webp");
  }
}
body.distributors.public main {
  background-image: url("distributors/world_map.svg");
  background-repeat: no-repeat;
  background-size: 60% auto;
  background-position: right 150px;
}
body.distributors.public main h1 {
  color: #fff;
  margin-top: 7vw;
  margin-bottom: 0;
  margin-left: -35px;
  padding-left: 50px;
  background-image: url("plane_white.svg");
  background-repeat: no-repeat;
  background-position: left 0.3em;
}
body.distributors.public main h2 {
  max-width: 550px;
  margin-bottom: 0;
}
body.distributors.public main p:first-of-type {
  margin-top: 0;
}
body.distributors.public main nav {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  margin-bottom: 40px;
  margin-top: 40px;
  padding: 0 15px;
}
body.distributors.public main nav a {
  align-self: flex-end;
  text-decoration: none;
  text-align: center;
  color: #7F7F7F;
  font-size: 1.4rem;
  margin-right: 20px;
  margin-bottom: 20px;
}
body.distributors.public main nav a[href="#new-zealand"] figure img {
  margin-top: 10px;
  margin-bottom: 5px;
}
body.distributors.public main nav figure {
  margin: 0;
  width: 60px;
}
body.distributors.public main nav figure img {
  margin-top: 5px;
  padding: 1px;
  background: #fff;
  border: 1px solid #939393;
  max-height: 40px;
}
body.distributors.public main section.country {
  border-left: 2px solid #b2b1b7;
  border-bottom: 2px solid #b2b1b7;
  margin: 15px;
  margin-bottom: 25px;
}
body.distributors.public main section.country h2 {
  margin: 0 0 10px 8px;
  text-transform: uppercase;
  color: #000;
  font-size: 1.3em;
}
body.distributors.public main section.country h2 img {
  max-width: 40px;
  max-height: 30px;
  border: 1px solid #939393;
}
body.distributors.public main section.country a {
  display: inline-block;
  width: 247px;
  height: 94px;
  margin: 10px 35px 0 8px;
  background-size: 100% 100%;
  background-position: 0 -1px;
  position: relative;
}
body.distributors.public main section.country a img {
  display: block;
  transition: opacity 0.5s;
}
body.distributors.public main section.country a:hover img, body.distributors.public main section.country a:active img {
  opacity: 0;
}

@media screen and (max-width: 1023px) {
  body.distributors.public main {
    background-size: 100% auto;
    background-position: center top;
  }
  body.distributors.public main h1 {
    color: #a28522;
    margin-top: 35vw;
  }
}
.button, nav.admin a, .small_button, input[type=submit], textarea[type=submit] {
  text-decoration: none;
  border: none;
  display: inline-block;
  padding: 0.2em 0.5em;
  color: #fff;
  background: #a28522;
  font-size: 2.4rem;
  cursor: pointer;
}

.small_button {
  font-size: 1.8rem;
}

.add_button, .delete_button, a.remove_nested_fields, a.add_nested_fields, div.shopping_cart_form a.add_variant {
  display: inline-block;
  background: #31333C;
  line-height: 1.4em;
  text-decoration: none;
  color: #fff;
  background-image: url("plus.png");
  background-repeat: no-repeat;
  background-position: 8px center;
  padding: 0 15px 0 30px;
  border-radius: 12px;
}

#shopping_cart {
  margin-top: 10px;
  background: #31333C;
  border-bottom: 1px solid #a28522;
}
#shopping_cart a, #shopping_cart a:link, #shopping_cart a:hover, #shopping_cart a:visited, #shopping_cart a:active {
  padding: 10px 20px;
  display: block;
  color: #fff;
  text-decoration: none;
}
#shopping_cart a:hover, #shopping_cart a.active, #shopping_cart a:link:hover, #shopping_cart a:link.active, #shopping_cart a:hover:hover, #shopping_cart a:hover.active, #shopping_cart a:visited:hover, #shopping_cart a:visited.active, #shopping_cart a:active:hover, #shopping_cart a:active.active {
  color: #a28522;
}
#shopping_cart a:hover h3, #shopping_cart a:hover p, #shopping_cart a.active h3, #shopping_cart a.active p, #shopping_cart a:link:hover h3, #shopping_cart a:link:hover p, #shopping_cart a:link.active h3, #shopping_cart a:link.active p, #shopping_cart a:hover:hover h3, #shopping_cart a:hover:hover p, #shopping_cart a:hover.active h3, #shopping_cart a:hover.active p, #shopping_cart a:visited:hover h3, #shopping_cart a:visited:hover p, #shopping_cart a:visited.active h3, #shopping_cart a:visited.active p, #shopping_cart a:active:hover h3, #shopping_cart a:active:hover p, #shopping_cart a:active.active h3, #shopping_cart a:active.active p {
  color: #a28522;
  background-position: right 0 top -30px;
}
#shopping_cart h3 {
  margin: 0;
  padding: 0;
  color: #fff;
  background: url("shopping_cart.svg") no-repeat;
  background-position: right 0 top 0;
}
#shopping_cart p {
  color: #fff;
  text-align: right;
  margin: 0;
  padding: 0;
}

li.shopping_cart a, li.shopping_cart a:link, li.shopping_cart a:hover, li.shopping_cart a:visited, li.shopping_cart a:active {
  display: block;
  padding: 0 20px;
  color: #fff;
  text-decoration: none;
}
li.shopping_cart a h3, li.shopping_cart a:link h3, li.shopping_cart a:hover h3, li.shopping_cart a:visited h3, li.shopping_cart a:active h3 {
  font-size: 1em;
  margin: 0;
  padding: 0;
  background-image: url("shopping_cart.svg");
  background-repeat: no-repeat;
  background-position: calc(50% - 80px) 2px;
}
li.shopping_cart a:hover, li.shopping_cart a.active, li.shopping_cart a:link:hover, li.shopping_cart a:link.active, li.shopping_cart a:hover:hover, li.shopping_cart a:hover.active, li.shopping_cart a:visited:hover, li.shopping_cart a:visited.active, li.shopping_cart a:active:hover, li.shopping_cart a:active.active {
  color: #a28522;
  background-position: calc(50% - 80px) -28px;
}
li.shopping_cart p, li.shopping_cart ul {
  display: none;
}

table.shopping_cart, table.order {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 0.5em;
}
table.shopping_cart th, table.order th {
  padding: 0.4em 0.6em;
  text-align: left;
  background: #31333C;
  color: #a28522;
}
table.shopping_cart tr:nth-child(odd), table.order tr:nth-child(odd) {
  background: rgba(162, 133, 34, 0.1);
}
table.shopping_cart td, table.order td {
  padding: 0.4em 0.6em;
  border: 1px solid #ffffff;
}
table.shopping_cart td.delete_item, table.order td.delete_item {
  width: 16px;
}
table.shopping_cart td.delete_item button, table.order td.delete_item button {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: none;
  background: none;
  padding: 0;
  margin: 0;
}
table.shopping_cart td.delete_item img, table.order td.delete_item img {
  max-width: 16px;
}
table.shopping_cart td.item_price, table.order td.item_price {
  text-align: right;
  white-space: nowrap;
}

@media screen and (max-width: 767px) {
  table.shopping_cart thead, table.shopping_cart tbody, table.shopping_cart th, table.shopping_cart td, table.shopping_cart tr, table.order thead, table.order tbody, table.order th, table.order td, table.order tr {
    display: block;
  }
  table.shopping_cart th, table.order th {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }
  table.shopping_cart td, table.order td {
    border: none;
    position: relative;
    height: 1.4em;
    padding-left: 50%;
  }
  table.shopping_cart td.item_price, table.order td.item_price {
    text-align: left;
  }
  table.shopping_cart td:before, table.order td:before {
    position: absolute;
    top: 10px;
    left: 15px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
  }
  html[lang=de] table.shopping_cart td:nth-of-type(1):before, html[lang=de] table.order td:nth-of-type(1):before {
    content: "Name";
  }
  html[lang=de] table.shopping_cart td:nth-of-type(2):before, html[lang=de] table.order td:nth-of-type(2):before {
    content: "Art.Nr.";
  }
  html[lang=de] table.shopping_cart td:nth-of-type(3):before, html[lang=de] table.order td:nth-of-type(3):before {
    content: "Farbe";
  }
  html[lang=de] table.shopping_cart td:nth-of-type(4):before, html[lang=de] table.order td:nth-of-type(4):before {
    content: "Größe";
  }
  html[lang=de] table.shopping_cart td:nth-of-type(5):before, html[lang=de] table.order td:nth-of-type(5):before {
    content: "Einzelpreis";
  }
  html[lang=de] table.shopping_cart td:nth-of-type(6):before, html[lang=de] table.order td:nth-of-type(6):before {
    content: "Stückzahl";
  }
  html[lang=de] table.shopping_cart td:nth-of-type(7):before, html[lang=de] table.order td:nth-of-type(7):before {
    content: "Preis";
  }
  html[lang=de] table.shopping_cart td:nth-of-type(8):before, html[lang=de] table.order td:nth-of-type(8):before {
    content: "Löschen";
  }
  html[lang=en] table.shopping_cart td:nth-of-type(1):before, html[lang=en] table.order td:nth-of-type(1):before {
    content: "Name";
  }
  html[lang=en] table.shopping_cart td:nth-of-type(2):before, html[lang=en] table.order td:nth-of-type(2):before {
    content: "SKU";
  }
  html[lang=en] table.shopping_cart td:nth-of-type(3):before, html[lang=en] table.order td:nth-of-type(3):before {
    content: "Color";
  }
  html[lang=en] table.shopping_cart td:nth-of-type(4):before, html[lang=en] table.order td:nth-of-type(4):before {
    content: "Size";
  }
  html[lang=en] table.shopping_cart td:nth-of-type(5):before, html[lang=en] table.order td:nth-of-type(5):before {
    content: "Unit price";
  }
  html[lang=en] table.shopping_cart td:nth-of-type(6):before, html[lang=en] table.order td:nth-of-type(6):before {
    content: "Quantity";
  }
  html[lang=en] table.shopping_cart td:nth-of-type(7):before, html[lang=en] table.order td:nth-of-type(7):before {
    content: "Price";
  }
  html[lang=en] table.shopping_cart td:nth-of-type(8):before, html[lang=en] table.order td:nth-of-type(8):before {
    content: "Delete";
  }
}
p.cart_full_price {
  text-align: right;
  margin-right: 38px;
  margin-top: 1em;
}
p.cart_full_price .price {
  font-size: 1.6em;
  color: #a28522;
}
p.shop_more {
  margin-top: 0;
  float: left;
  width: 50%;
}

div.info {
  background: #a28522;
  color: #fff;
  padding: 15px 15px 15px 40px;
  position: relative;
}
div.info:before {
  content: "!";
  position: absolute;
  top: 0;
  left: 15px;
  font-size: 3em;
  font-weight: bold;
}

.button, nav.admin a, .small_button, input[type=submit], textarea[type=submit] {
  text-decoration: none;
  border: none;
  display: inline-block;
  padding: 0.2em 0.5em;
  color: #fff;
  background: #a28522;
  font-size: 2.4rem;
  cursor: pointer;
}

.small_button {
  font-size: 1.8rem;
}

.add_button, .delete_button, a.remove_nested_fields, a.add_nested_fields, div.shopping_cart_form a.add_variant {
  display: inline-block;
  background: #31333C;
  line-height: 1.4em;
  text-decoration: none;
  color: #fff;
  background-image: url("plus.png");
  background-repeat: no-repeat;
  background-position: 8px center;
  padding: 0 15px 0 30px;
  border-radius: 12px;
}

table.orders {
  border-collapse: collapse;
  width: 100%;
  margin: 1em 0;
}
table.orders th {
  padding: 0.4em 0.6em;
  text-align: left;
  background: #31333C;
  color: #a28522;
}
table.orders td {
  padding: 0.4em 0.6em;
  border-bottom: 1px solid #a28522;
}
table.orders td.price {
  text-align: right;
  white-space: nowrap;
}

div.pagination a, div.pagination em, div.pagination span {
  display: inline-block;
  padding: 0 0.3em;
}
div.pagination a {
  text-decoration: none;
  border: 1px solid #a28522;
}

@media screen and (max-width: 570px) {
  div.pagination {
    padding: 0 10px;
  }
  div.pagination a, div.pagination em, div.pagination span {
    margin-bottom: 10px;
  }
  table.orders thead, table.orders tbody, table.orders th, table.orders td, table.orders tr {
    display: block;
  }
  table.orders tr {
    border-bottom: 1px solid #a28522;
  }
  table.orders th {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }
  table.orders td {
    border: none;
    position: relative;
    height: 1.4em;
    padding-left: 35%;
  }
  table.orders td.price {
    text-align: left;
  }
  table.orders td:before {
    position: absolute;
    top: 10px;
    left: 15px;
    width: 30%;
    padding-right: 10px;
    white-space: nowrap;
  }
  html[lang=de] table.orders td:nth-of-type(1):before {
    content: "Datum";
  }
  html[lang=de] table.orders td:nth-of-type(2):before {
    content: "Kunde";
  }
  html[lang=de] table.orders td:nth-of-type(3):before {
    content: "Gesamtpreis";
  }
  html[lang=en] table.orders td:nth-of-type(1):before {
    content: "Date";
  }
  html[lang=en] table.orders td:nth-of-type(2):before {
    content: "Customer";
  }
  html[lang=en] table.orders td:nth-of-type(3):before {
    content: "Total price";
  }
}
.button, nav.admin a, .small_button, input[type=submit], textarea[type=submit] {
  text-decoration: none;
  border: none;
  display: inline-block;
  padding: 0.2em 0.5em;
  color: #fff;
  background: #a28522;
  font-size: 2.4rem;
  cursor: pointer;
}

.small_button {
  font-size: 1.8rem;
}

.add_button, .delete_button, a.remove_nested_fields, a.add_nested_fields, div.shopping_cart_form a.add_variant {
  display: inline-block;
  background: #31333C;
  line-height: 1.4em;
  text-decoration: none;
  color: #fff;
  background-image: url("plus.png");
  background-repeat: no-repeat;
  background-position: 8px center;
  padding: 0 15px 0 30px;
  border-radius: 12px;
}

table.admin {
  width: 100%;
  border-collapse: collapse;
}
table.admin th {
  padding: 0.4em 0.6em;
  text-align: left;
  background: #31333C;
  color: #a28522;
}
table.admin td {
  padding: 0.4em 0.6em;
  vertical-align: top;
  border-bottom: 1px solid #a28522;
}
table.admin td.green:before {
  content: "• ";
  color: green;
}
table.admin td.red:before {
  content: "• ";
  color: red;
}

@media screen and (max-width: 767px) {
  body.users.index table.admin thead, body.users.index table.admin tbody, body.users.index table.admin th, body.users.index table.admin td, body.users.index table.admin tr {
    display: block;
  }
  body.users.index table.admin th {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }
  body.users.index table.admin tr {
    clear: left;
    border-bottom: 1px solid #a28522;
  }
  body.users.index table.admin tr:after {
    content: " ";
    display: block;
    height: 0;
    clear: both;
  }
  body.users.index table.admin td {
    border: none;
    position: relative;
    line-height: 1.2em;
    padding-left: 30%;
  }
  body.users.index table.admin td:nth-of-type(3), body.users.index table.admin td:nth-of-type(4), body.users.index table.admin td:nth-of-type(5) {
    padding-left: 20px;
    float: left;
  }
  body.users.index table.admin td:before {
    position: absolute;
    top: 10px;
    left: 15px;
    width: 25%;
    padding-right: 10px;
    white-space: nowrap;
  }
  html[lang=de] body.users.index table.admin td:nth-of-type(1):before {
    content: "Firma";
  }
  html[lang=de] body.users.index table.admin td:nth-of-type(2):before {
    content: "Name";
  }
  html[lang=en] body.users.index table.admin td:nth-of-type(1):before {
    content: "Company";
  }
  html[lang=en] body.users.index table.admin td:nth-of-type(2):before {
    content: "Name";
  }
}
nav.admin {
  text-align: right;
}
a.add_nested_fields {
  color: #fff !important;
}

a.remove_nested_fields {
  color: #fff !important;
  float: right;
  background-image: url("minus.png");
}

.delete_button {
  color: #fff !important;
  background-image: url("minus.png");
  border: none;
  cursor: pointer;
}

[data-controller=nested-form] legend {
  margin-top: 1em;
  color: #fff;
  background: #a28522;
  display: block;
}

.glightbox-container {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999 !important;
  overflow: hidden;
  -ms-touch-action: none;
  touch-action: none;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  text-size-adjust: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  outline: 0;
}

.glightbox-container.inactive {
  display: none;
}

.glightbox-container .gcontainer {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 9999;
  overflow: hidden;
}

.glightbox-container .gslider {
  -webkit-transition: -webkit-transform 0.4s ease;
  transition: -webkit-transform 0.4s ease;
  transition: transform 0.4s ease;
  transition: transform 0.4s ease, -webkit-transform 0.4s ease;
  height: 100%;
  left: 0;
  top: 0;
  width: 100%;
  position: relative;
  overflow: hidden;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.glightbox-container .gslide {
  width: 100%;
  position: absolute;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  opacity: 0;
}

.glightbox-container .gslide.current {
  opacity: 1;
  z-index: 99999;
  position: relative;
}

.glightbox-container .gslide.prev {
  opacity: 1;
  z-index: 9999;
}

.glightbox-container .gslide-inner-content {
  width: 100%;
}

.glightbox-container .ginner-container {
  position: relative;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  max-width: 100%;
  margin: auto;
  height: 100vh;
}

.glightbox-container .ginner-container.gvideo-container {
  width: 100%;
}

.glightbox-container .ginner-container.desc-bottom, .glightbox-container .ginner-container.desc-top {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.glightbox-container .ginner-container.desc-left, .glightbox-container .ginner-container.desc-right {
  max-width: 100% !important;
}

.gslide iframe, .gslide video {
  outline: 0 !important;
  border: none;
  min-height: 165px;
  -webkit-overflow-scrolling: touch;
  -ms-touch-action: auto;
  touch-action: auto;
}

.gslide:not(.current) {
  pointer-events: none;
}

.gslide-image {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.gslide-image img {
  max-height: 100vh;
  display: block;
  padding: 0;
  float: none;
  outline: 0;
  border: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  max-width: 100vw;
  width: auto;
  height: auto;
  -o-object-fit: cover;
  object-fit: cover;
  -ms-touch-action: none;
  touch-action: none;
  margin: auto;
  min-width: 200px;
}

.desc-bottom .gslide-image img, .desc-top .gslide-image img {
  width: auto;
}

.desc-left .gslide-image img, .desc-right .gslide-image img {
  width: auto;
  max-width: 100%;
}

.gslide-image img.zoomable {
  position: relative;
}

.gslide-image img.dragging {
  cursor: -webkit-grabbing !important;
  cursor: grabbing !important;
  -webkit-transition: none;
  transition: none;
}

.gslide-video {
  position: relative;
  max-width: 100vh;
  width: 100% !important;
}

.gslide-video .plyr__poster-enabled.plyr--loading .plyr__poster {
  display: none;
}

.gslide-video .gvideo-wrapper {
  width: 100%;
  margin: auto;
}

.gslide-video::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(255, 0, 0, 0.34);
  display: none;
}

.gslide-video.playing::before {
  display: none;
}

.gslide-video.fullscreen {
  max-width: 100% !important;
  min-width: 100%;
  height: 75vh;
}

.gslide-video.fullscreen video {
  max-width: 100% !important;
  width: 100% !important;
}

.gslide-inline {
  background: #fff;
  text-align: left;
  max-height: calc(100vh - 40px);
  overflow: auto;
  max-width: 100%;
  margin: auto;
}

.gslide-inline .ginlined-content {
  padding: 20px;
  width: 100%;
}

.gslide-inline .dragging {
  cursor: -webkit-grabbing !important;
  cursor: grabbing !important;
  -webkit-transition: none;
  transition: none;
}

.ginlined-content {
  overflow: auto;
  display: block !important;
  opacity: 1;
}

.gslide-external {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  min-width: 100%;
  background: #fff;
  padding: 0;
  overflow: auto;
  max-height: 75vh;
  height: 100%;
}

.gslide-media {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: auto;
}

.zoomed .gslide-media {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.desc-bottom .gslide-media, .desc-top .gslide-media {
  margin: 0 auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.gslide-description {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 100%;
  flex: 1 0 100%;
}

.gslide-description.description-left, .gslide-description.description-right {
  max-width: 100%;
}

.gslide-description.description-bottom, .gslide-description.description-top {
  margin: 0 auto;
  width: 100%;
}

.gslide-description p {
  margin-bottom: 12px;
}

.gslide-description p:last-child {
  margin-bottom: 0;
}

.zoomed .gslide-description {
  display: none;
}

.glightbox-button-hidden {
  display: none;
}

.glightbox-mobile .glightbox-container .gslide-description {
  height: auto !important;
  width: 100%;
  position: absolute;
  bottom: 0;
  padding: 19px 11px;
  max-width: 100vw !important;
  -webkit-box-ordinal-group: 3 !important;
  -ms-flex-order: 2 !important;
  order: 2 !important;
  max-height: 78vh;
  overflow: auto !important;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, 0.75)));
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0.75) 100%);
  -webkit-transition: opacity 0.3s linear;
  transition: opacity 0.3s linear;
  padding-bottom: 50px;
}

.glightbox-mobile .glightbox-container .gslide-title {
  color: #fff;
  font-size: 1em;
}

.glightbox-mobile .glightbox-container .gslide-desc {
  color: #a1a1a1;
}

.glightbox-mobile .glightbox-container .gslide-desc a {
  color: #fff;
  font-weight: 700;
}

.glightbox-mobile .glightbox-container .gslide-desc * {
  color: inherit;
}

.glightbox-mobile .glightbox-container .gslide-desc .desc-more {
  color: #fff;
  opacity: 0.4;
}

.gdesc-open .gslide-media {
  -webkit-transition: opacity 0.5s ease;
  transition: opacity 0.5s ease;
  opacity: 0.4;
}

.gdesc-open .gdesc-inner {
  padding-bottom: 30px;
}

.gdesc-closed .gslide-media {
  -webkit-transition: opacity 0.5s ease;
  transition: opacity 0.5s ease;
  opacity: 1;
}

.greset {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.gabsolute {
  position: absolute;
}

.grelative {
  position: relative;
}

.glightbox-desc {
  display: none !important;
}

.glightbox-open {
  overflow: hidden;
}

.gloader {
  height: 25px;
  width: 25px;
  -webkit-animation: lightboxLoader 0.8s infinite linear;
  animation: lightboxLoader 0.8s infinite linear;
  border: 2px solid #fff;
  border-right-color: transparent;
  border-radius: 50%;
  position: absolute;
  display: block;
  z-index: 9999;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 47%;
}

.goverlay {
  width: 100%;
  height: calc(100vh + 1px);
  position: fixed;
  top: -1px;
  left: 0;
  background: #000;
  will-change: opacity;
}

.glightbox-mobile .goverlay {
  background: #000;
}

.gclose, .gnext, .gprev {
  z-index: 99999;
  cursor: pointer;
  width: 26px;
  height: 44px;
  border: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.gclose svg, .gnext svg, .gprev svg {
  display: block;
  width: 25px;
  height: auto;
  margin: 0;
  padding: 0;
}

.gclose.disabled, .gnext.disabled, .gprev.disabled {
  opacity: 0.1;
}

.gclose .garrow, .gnext .garrow, .gprev .garrow {
  stroke: #fff;
}

.gbtn.focused {
  outline: 2px solid #0f3d81;
}

iframe.wait-autoplay {
  opacity: 0;
}

.glightbox-closing .gclose, .glightbox-closing .gnext, .glightbox-closing .gprev {
  opacity: 0 !important;
}

.glightbox-clean .gslide-description {
  background: #fff;
}

.glightbox-clean .gdesc-inner {
  padding: 22px 20px;
}

.glightbox-clean .gslide-title {
  font-size: 1em;
  font-weight: 400;
  font-family: arial;
  color: #000;
  margin-bottom: 19px;
  line-height: 1.4em;
}

.glightbox-clean .gslide-desc {
  font-size: 0.86em;
  margin-bottom: 0;
  font-family: arial;
  line-height: 1.4em;
}

.glightbox-clean .gslide-video {
  background: #000;
}

.glightbox-clean .gclose, .glightbox-clean .gnext, .glightbox-clean .gprev {
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 4px;
}

.glightbox-clean .gclose path, .glightbox-clean .gnext path, .glightbox-clean .gprev path {
  fill: #fff;
}

.glightbox-clean .gprev {
  position: absolute;
  top: -100%;
  left: 30px;
  width: 40px;
  height: 50px;
}

.glightbox-clean .gnext {
  position: absolute;
  top: -100%;
  right: 30px;
  width: 40px;
  height: 50px;
}

.glightbox-clean .gclose {
  width: 35px;
  height: 35px;
  top: 15px;
  right: 10px;
  position: absolute;
}

.glightbox-clean .gclose svg {
  width: 18px;
  height: auto;
}

.glightbox-clean .gclose:hover {
  opacity: 1;
}

.gfadeIn {
  -webkit-animation: gfadeIn 0.5s ease;
  animation: gfadeIn 0.5s ease;
}

.gfadeOut {
  -webkit-animation: gfadeOut 0.5s ease;
  animation: gfadeOut 0.5s ease;
}

.gslideOutLeft {
  -webkit-animation: gslideOutLeft 0.3s ease;
  animation: gslideOutLeft 0.3s ease;
}

.gslideInLeft {
  -webkit-animation: gslideInLeft 0.3s ease;
  animation: gslideInLeft 0.3s ease;
}

.gslideOutRight {
  -webkit-animation: gslideOutRight 0.3s ease;
  animation: gslideOutRight 0.3s ease;
}

.gslideInRight {
  -webkit-animation: gslideInRight 0.3s ease;
  animation: gslideInRight 0.3s ease;
}

.gzoomIn {
  -webkit-animation: gzoomIn 0.5s ease;
  animation: gzoomIn 0.5s ease;
}

.gzoomOut {
  -webkit-animation: gzoomOut 0.5s ease;
  animation: gzoomOut 0.5s ease;
}

@-webkit-keyframes lightboxLoader {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes lightboxLoader {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes gfadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes gfadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes gfadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes gfadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-webkit-keyframes gslideInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-60%, 0, 0);
    transform: translate3d(-60%, 0, 0);
  }
  to {
    visibility: visible;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@keyframes gslideInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-60%, 0, 0);
    transform: translate3d(-60%, 0, 0);
  }
  to {
    visibility: visible;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@-webkit-keyframes gslideOutLeft {
  from {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    -webkit-transform: translate3d(-60%, 0, 0);
    transform: translate3d(-60%, 0, 0);
    opacity: 0;
    visibility: hidden;
  }
}
@keyframes gslideOutLeft {
  from {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    -webkit-transform: translate3d(-60%, 0, 0);
    transform: translate3d(-60%, 0, 0);
    opacity: 0;
    visibility: hidden;
  }
}
@-webkit-keyframes gslideInRight {
  from {
    opacity: 0;
    visibility: visible;
    -webkit-transform: translate3d(60%, 0, 0);
    transform: translate3d(60%, 0, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@keyframes gslideInRight {
  from {
    opacity: 0;
    visibility: visible;
    -webkit-transform: translate3d(60%, 0, 0);
    transform: translate3d(60%, 0, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@-webkit-keyframes gslideOutRight {
  from {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    -webkit-transform: translate3d(60%, 0, 0);
    transform: translate3d(60%, 0, 0);
    opacity: 0;
  }
}
@keyframes gslideOutRight {
  from {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    -webkit-transform: translate3d(60%, 0, 0);
    transform: translate3d(60%, 0, 0);
    opacity: 0;
  }
}
@-webkit-keyframes gzoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  to {
    opacity: 1;
  }
}
@keyframes gzoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes gzoomOut {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  to {
    opacity: 0;
  }
}
@keyframes gzoomOut {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  to {
    opacity: 0;
  }
}
@media (min-width: 769px) {
  .glightbox-container .ginner-container {
    width: auto;
    height: auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .glightbox-container .ginner-container.desc-top .gslide-description {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }
  .glightbox-container .ginner-container.desc-top .gslide-image, .glightbox-container .ginner-container.desc-top .gslide-image img {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
  .glightbox-container .ginner-container.desc-left .gslide-description {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }
  .glightbox-container .ginner-container.desc-left .gslide-image {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
  .gslide-image img {
    max-height: 97vh;
    max-width: 100%;
  }
  .gslide-image img.zoomable {
    cursor: -webkit-zoom-in;
    cursor: zoom-in;
  }
  .zoomed .gslide-image img.zoomable {
    cursor: -webkit-grab;
    cursor: grab;
  }
  .gslide-inline {
    max-height: 95vh;
  }
  .gslide-external {
    max-height: 100vh;
  }
  .gslide-description.description-left, .gslide-description.description-right {
    max-width: 275px;
  }
  .glightbox-open {
    height: auto;
  }
  .goverlay {
    background: rgba(0, 0, 0, 0.92);
  }
  .glightbox-clean .gslide-media {
    -webkit-box-shadow: 1px 2px 9px 0 rgba(0, 0, 0, 0.65);
    box-shadow: 1px 2px 9px 0 rgba(0, 0, 0, 0.65);
  }
  .glightbox-clean .description-left .gdesc-inner, .glightbox-clean .description-right .gdesc-inner {
    position: absolute;
    height: 100%;
    overflow-y: auto;
  }
  .glightbox-clean .gclose, .glightbox-clean .gnext, .glightbox-clean .gprev {
    background-color: rgba(0, 0, 0, 0.32);
  }
  .glightbox-clean .gclose:hover, .glightbox-clean .gnext:hover, .glightbox-clean .gprev:hover {
    background-color: rgba(0, 0, 0, 0.7);
  }
  .glightbox-clean .gprev {
    top: 45%;
  }
  .glightbox-clean .gnext {
    top: 45%;
  }
}
@media (min-width: 992px) {
  .glightbox-clean .gclose {
    opacity: 0.7;
    right: 20px;
  }
}
@media screen and (max-height: 420px) {
  .goverlay {
    background: #000;
  }
}
.goverlay {
  background: #fff;
}

.glightbox-mobile .goverlay {
  background: #fff;
}

@media (min-width: 769px) {
  .goverlay {
    background: rgba(255, 255, 255, 0.92);
  }
}
@media (min-width: 769px) {
  .glightbox-clean .gslide-media {
    box-shadow: none;
  }
}
:root {
  --ss-primary-color: #5897fb;
  --ss-bg-color: #ffffff;
  --ss-font-color: #4d4d4d;
  --ss-font-placeholder-color: #8d8d8d;
  --ss-disabled-color: #dcdee2;
  --ss-border-color: #dcdee2;
  --ss-highlight-color: #fffb8c;
  --ss-success-color: #00b755;
  --ss-error-color: #dc3545;
  --ss-focus-color: #5897fb;
  --ss-main-height: 30px;
  --ss-content-height: 300px;
  --ss-spacing-l: 7px;
  --ss-spacing-m: 5px;
  --ss-spacing-s: 3px;
  --ss-animation-timing: 0.2s;
  --ss-border-radius: 4px;
}

@keyframes ss-valueIn {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes ss-valueOut {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0);
    opacity: 0;
  }
}
.ss-hide {
  display: none !important;
}

.ss-main {
  display: flex;
  flex-direction: row;
  position: relative;
  user-select: none;
  color: var(--ss-font-color);
  min-height: var(--ss-main-height);
  width: 100%;
  padding: var(--ss-spacing-s);
  cursor: pointer;
  border: 1px solid var(--ss-border-color);
  border-radius: var(--ss-border-radius);
  background-color: var(--ss-bg-color);
  outline: 0;
  box-sizing: border-box;
  transition: background-color var(--ss-animation-timing);
  overflow: hidden;
}

.ss-main:focus {
  box-shadow: 0 0 5px var(--ss-focus-color);
}

.ss-main.ss-disabled {
  background-color: var(--ss-disabled-color);
  cursor: not-allowed;
}

.ss-main.ss-disabled .ss-values .ss-disabled {
  color: var(--ss-font-color);
}

.ss-main.ss-disabled .ss-values .ss-value .ss-value-delete {
  cursor: not-allowed;
}

.ss-main.ss-open-above {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.ss-main.ss-open-below {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.ss-main .ss-values {
  display: inline-flex;
  flex-wrap: wrap;
  gap: var(--ss-spacing-m);
  flex: 1 1 100%;
}

.ss-main .ss-values .ss-placeholder {
  display: flex;
  padding: var(--ss-spacing-s) var(--ss-spacing-m) var(--ss-spacing-s) var(--ss-spacing-m);
  margin: auto 0px auto 0px;
  line-height: 1em;
  align-items: center;
  width: 100%;
  color: var(--ss-font-placeholder-color);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ss-main .ss-values .ss-max {
  display: flex;
  user-select: none;
  align-items: center;
  width: fit-content;
  font-size: 12px;
  color: var(--ss-bg-color);
  line-height: 1;
  padding: var(--ss-spacing-s) var(--ss-spacing-m);
  background-color: var(--ss-primary-color);
  border-radius: var(--ss-border-radius);
}

.ss-main .ss-values .ss-single {
  display: flex;
  margin: auto 0px auto var(--ss-spacing-s);
}

.ss-main .ss-values .ss-value {
  display: flex;
  user-select: none;
  align-items: center;
  width: fit-content;
  background-color: var(--ss-primary-color);
  border-radius: var(--ss-border-radius);
  animation-name: ss-valueIn;
  animation-duration: var(--ss-animation-timing);
  animation-timing-function: ease-out;
  animation-fill-mode: both;
}

.ss-main .ss-values .ss-value.ss-value-out {
  animation-name: ss-valueOut;
  animation-duration: var(--ss-animation-timing);
  animation-timing-function: ease-out;
}

.ss-main .ss-values .ss-value .ss-value-text {
  font-size: 12px;
  color: var(--ss-bg-color);
  line-height: 1;
  padding: var(--ss-spacing-s) var(--ss-spacing-m);
}

.ss-main .ss-values .ss-value .ss-value-delete {
  display: flex;
  align-items: center;
  height: var(--ss-spacing-l);
  width: var(--ss-spacing-l);
  padding: var(--ss-spacing-s) var(--ss-spacing-m);
  cursor: pointer;
  border-left: solid 1px var(--ss-bg-color);
  box-sizing: content-box;
}

.ss-main .ss-values .ss-value .ss-value-delete svg {
  height: var(--ss-spacing-l);
  width: var(--ss-spacing-l);
}

.ss-main .ss-values .ss-value .ss-value-delete svg path {
  fill: none;
  stroke: var(--ss-bg-color);
  stroke-width: 18;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.ss-main .ss-deselect {
  flex: 0 1 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  height: auto;
  padding: 0 var(--ss-spacing-m) 0 var(--ss-spacing-m);
}

.ss-main .ss-deselect svg {
  width: 8px;
  height: 8px;
}

.ss-main .ss-deselect svg path {
  fill: none;
  stroke: var(--ss-font-color);
  stroke-width: 20;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.ss-main .ss-arrow {
  flex: 0 1 auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 12px;
  height: 12px;
  margin: auto var(--ss-spacing-m) auto var(--ss-spacing-m);
}

.ss-main .ss-arrow path {
  fill: none;
  stroke: var(--ss-font-color);
  stroke-width: 18;
  stroke-linecap: round;
  stroke-linejoin: round;
  transition-timing-function: ease-out;
  transition: var(--ss-animation-timing);
}

.ss-content {
  position: absolute;
  display: flex;
  height: auto;
  flex-direction: column;
  width: auto;
  max-height: var(--ss-content-height);
  box-sizing: border-box;
  border: solid 1px var(--ss-border-color);
  background-color: var(--ss-bg-color);
  transition: transform var(--ss-animation-timing), opacity var(--ss-animation-timing);
  opacity: 0;
  transform: scaleY(0);
  transform-origin: center top;
  overflow: hidden;
  z-index: 10000;
}

.ss-content.ss-relative {
  position: relative;
  height: 100%;
}

.ss-content.ss-open-above {
  flex-direction: column-reverse;
  opacity: 1;
  transform: scaleY(1);
  transform-origin: center bottom;
  border-top-left-radius: var(--ss-border-radius);
  border-top-right-radius: var(--ss-border-radius);
}

.ss-content.ss-open-below {
  opacity: 1;
  transform: scaleY(1);
  transform-origin: center top;
  border-bottom-left-radius: var(--ss-border-radius);
  border-bottom-right-radius: var(--ss-border-radius);
}

.ss-content .ss-search {
  flex: 0 1 auto;
  display: flex;
  flex-direction: row;
  padding: var(--ss-spacing-l) var(--ss-spacing-l) var(--ss-spacing-m) var(--ss-spacing-l);
}

.ss-content .ss-search input {
  display: inline-flex;
  font-size: inherit;
  line-height: inherit;
  flex: 1 1 auto;
  width: 100%;
  min-width: 0px;
  padding: var(--ss-spacing-m) var(--ss-spacing-l);
  margin: 0;
  border: 1px solid var(--ss-border-color);
  border-radius: var(--ss-border-radius);
  background-color: var(--ss-bg-color);
  outline: 0;
  text-align: left;
  box-sizing: border-box;
}

.ss-content .ss-search input::placeholder {
  color: var(--ss-font-placeholder-color);
  vertical-align: middle;
}

.ss-content .ss-search input:focus {
  box-shadow: 0 0 5px var(--ss-focus-color);
}

.ss-content .ss-search .ss-addable {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  flex: 0 0 auto;
  height: auto;
  margin: 0 0 0 var(--ss-spacing-m);
  border: 1px solid var(--ss-border-color);
  border-radius: var(--ss-border-radius);
}

.ss-content .ss-search .ss-addable svg {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 0 1 auto;
  width: 12px;
  height: 12px;
  margin: auto var(--ss-spacing-m) auto var(--ss-spacing-m);
}

.ss-content .ss-search .ss-addable svg path {
  fill: none;
  stroke: var(--ss-font-color);
  stroke-width: 18;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.ss-content .ss-list {
  flex: 1 1 auto;
  height: auto;
  overflow-x: hidden;
  overflow-y: auto;
}

.ss-content .ss-list .ss-error {
  color: var(--ss-error-color);
  padding: var(--ss-spacing-l);
}

.ss-content .ss-list .ss-searching {
  color: var(--ss-font-color);
  padding: var(--ss-spacing-l);
}

.ss-content .ss-list .ss-optgroup.ss-close .ss-option {
  display: none !important;
}

.ss-content .ss-list .ss-optgroup .ss-optgroup-label {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: var(--ss-spacing-m) var(--ss-spacing-l) var(--ss-spacing-m) var(--ss-spacing-l);
}

.ss-content .ss-list .ss-optgroup .ss-optgroup-label .ss-optgroup-label-text {
  flex: 1 1 auto;
  font-weight: bold;
  color: var(--ss-font-color);
}

.ss-content .ss-list .ss-optgroup .ss-optgroup-label:has(.ss-arrow) {
  cursor: pointer;
}

.ss-content .ss-list .ss-optgroup .ss-optgroup-label .ss-optgroup-actions {
  flex: 0 1 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--ss-spacing-m);
}

.ss-content .ss-list .ss-optgroup .ss-optgroup-label .ss-optgroup-actions .ss-selectall {
  flex: 0 0 auto;
  display: flex;
  flex-direction: row;
  cursor: pointer;
}

.ss-content .ss-list .ss-optgroup .ss-optgroup-label .ss-optgroup-actions .ss-selectall:hover {
  opacity: 0.5;
}

.ss-content .ss-list .ss-optgroup .ss-optgroup-label .ss-optgroup-actions .ss-selectall.ss-selected svg path {
  stroke: var(--ss-error-color);
}

.ss-content .ss-list .ss-optgroup .ss-optgroup-label .ss-optgroup-actions .ss-selectall span {
  flex: 0 1 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 60%;
  text-align: center;
  padding: 0 var(--ss-spacing-s) 0 0;
}

.ss-content .ss-list .ss-optgroup .ss-optgroup-label .ss-optgroup-actions .ss-selectall svg {
  flex: 0 1 auto;
  width: 13px;
  height: 13px;
}

.ss-content .ss-list .ss-optgroup .ss-optgroup-label .ss-optgroup-actions .ss-selectall svg path {
  fill: none;
  stroke: var(--ss-success-color);
  stroke-linecap: round;
  stroke-linejoin: round;
}

.ss-content .ss-list .ss-optgroup .ss-optgroup-label .ss-optgroup-actions .ss-selectall svg:first-child {
  stroke-width: 5;
}

.ss-content .ss-list .ss-optgroup .ss-optgroup-label .ss-optgroup-actions .ss-selectall svg:last-child {
  stroke-width: 11;
}

.ss-content .ss-list .ss-optgroup .ss-optgroup-label .ss-optgroup-actions .ss-closable {
  flex: 0 1 auto;
  display: flex;
  flex-direction: row;
  cursor: pointer;
}

.ss-content .ss-list .ss-optgroup .ss-optgroup-label .ss-optgroup-actions .ss-closable .ss-arrow {
  flex: 1 1 auto;
  width: 10px;
  height: 10px;
}

.ss-content .ss-list .ss-optgroup .ss-optgroup-label .ss-optgroup-actions .ss-closable .ss-arrow path {
  fill: none;
  stroke: var(--ss-font-color);
  stroke-width: 18;
  stroke-linecap: round;
  stroke-linejoin: round;
  transition-timing-function: ease-out;
  transition: var(--ss-animation-timing);
}

.ss-content .ss-list .ss-optgroup .ss-option {
  padding: var(--ss-spacing-s) var(--ss-spacing-s) var(--ss-spacing-s) calc(var(--ss-spacing-l) * 3);
}

.ss-content .ss-list .ss-option {
  display: block;
  padding: var(--ss-spacing-m) var(--ss-spacing-l) var(--ss-spacing-m) var(--ss-spacing-l);
  white-space: normal;
  color: var(--ss-font-color);
  cursor: pointer;
  user-select: none;
}

.ss-content .ss-list .ss-option:hover {
  color: var(--ss-bg-color);
  background-color: var(--ss-primary-color);
}

.ss-content .ss-list .ss-option.ss-highlighted, .ss-content .ss-list .ss-option:not(.ss-disabled).ss-selected {
  color: var(--ss-bg-color);
  background-color: var(--ss-primary-color);
}

.ss-content .ss-list .ss-option.ss-disabled {
  cursor: not-allowed;
  background-color: var(--ss-disabled-color);
}

.ss-content .ss-list .ss-option.ss-disabled:hover {
  color: var(--ss-font-color);
}

.ss-content .ss-list .ss-option .ss-search-highlight {
  display: inline-block;
  background-color: var(--ss-highlight-color);
} 
:root {
  --ss-border-radius: 0px;
  --ss-border-color: #ece7d3;
  --ss-bg-color: #ece7d3;
}
